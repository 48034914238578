$color-lightblue: #26b8eb;
$color-brightred: #ef3e40;

$transition: all 0.2s ease-in-out;
$box-shadow-1: 0 12px 17px 3px, rgba(38, 184, 235, 0.85);
$box-shadow-strongblue: 0 0 8px 0 rgba(38, 184, 235, 0.7);



.wrap-footer {
    width: 100%;
    background-color: $color-lightblue;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 1));
    padding: 150px 0 120px;
    clip-path: polygon(0 30%, 0 100%, 100% 100%, 100% 30%, 50% 0);    
    -webkit-clip-path: polygon(0 30%, 0 100%, 100% 100%, 100% 30%, 50% 0);

}

.footer-header {
    width: fit-content;
    margin: 0 auto 50px; 

    & > h2 {
        color: #fff;
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.01px;
        margin-bottom: 10px;
    }

    & > .ld-cont-h-linediv {
        width: 80%;
        margin: 0 auto;
        border-bottom: 5px solid $color-brightred;
    }
}

.footer-container {
    width: 85%;
    max-width: 1800px;
    height: auto;
    margin: 60px auto;
    padding: 0;
    display: grid;
    grid-template-columns: 4fr 3fr 5fr;
    gap: 0;
}

.footer-container-1 {
    
    & > img {
        width: 200px;
        height: auto;
        margin-bottom: 30px;
    }
    & > p {
        color: #fff;
        width: 80%;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: 0px;
    }

}

.footer-container-2 {
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;

    & > h3 {
        width: 70%;
        margin: 0 auto;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: 18px;
    }

    & > div {
        width: 70%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        & svg {
            color: #fff;
        }

        & > a {
            color: #fff;
            font-size: 14px;
            font-weight: bold;
            text-decoration: none;
            margin: 0 0 10px -16px;
        }
    }
    
}

.footer-container-3 {
    padding: 0 0 0 60px;
}

.f-cont-3-item-1 {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 1.7;

    & > a {
        color: #fff;
        font-size: 14px;
        font-weight: normal;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.f-cont-3-item-2 {
    & > address {
        width: 70%;
        color: #fff;
        font-size: 14px;
        font-weight: normal;
        text-transform: capitalize;
    }

    & > div {
        color: #fff;
        font-size: 14px;
        font-weight: normal;
    }
}


.footer-cr > p {
    color: #fff;
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-size: 12px;
}







@media screen and (max-width: 1200px) {
    .wrap-footer {
        padding: 100px 0 100px;
        clip-path: polygon(0 15%, 0 100%, 100% 100%, 100% 15%, 50% 0);    
        -webkit-clip-path: polygon(0 15%, 0 100%, 100% 100%, 100% 15%, 50% 0);
    }

    .footer-header > .ld-cont-h-linediv {
        border-bottom: 3px solid $color-brightred;
    }
}



@media screen and (max-width: 1024px) {
    .wrap-footer {
        padding: 100px 0 100px;
        clip-path: polygon(0 15%, 0 100%, 100% 100%, 100% 15%, 50% 0);    
        -webkit-clip-path: polygon(0 15%, 0 100%, 100% 100%, 100% 15%, 50% 0);
    }

    .footer-container {
        width: 90%;
        height: auto;
        margin: 60px auto ;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0;
    }

    .footer-header > h2 {
        font-size: 30px;
    }


    .footer-container-1 > img {
        width: 70%;
    }
    .footer-container-1 > p {
        width: 90%;
    }


    .footer-container-3 {
        padding: 0 0 0 20px;
    }
    .f-cont-3-item-2 > address {
        width: 100%;
    }
}






@media screen and (max-width: 576px) {
    .wrap-footer {
        padding: 90px 0 100px;
        clip-path: polygon(0 10%, 0 100%, 100% 100%, 100% 10%, 50% 0);    
        -webkit-clip-path: polygon(0 10%, 0 100%, 100% 100%, 100% 10%, 50% 0);
    }

    .footer-container {
        width: 85%;
        max-width: 1800px;
        height: auto;
        margin: 60px auto 40px;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        gap: 0;
    }


    .footer-header > h2 {
        font-size: 20px;
    }
    .footer-header > .ld-cont-h-linediv {
        border-bottom: 3px solid $color-brightred;
    }


    .footer-container-1 {
        width: 90%;
        margin: 0 auto 30px;
    }
    .footer-container-1 > img {
        width: 50%;
    }
    .footer-container-1 > p {
        width: 100%;
        font-size: 12px;
        margin: 0 auto;
    }


    .footer-container-2 {
        padding: 30px 0 20px;
        border: none;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
    }
    .footer-container-2 > h3 {
        width: 85%;
        font-size: 18px;
    }
    .footer-container-2 > div {
        width: 85%;
    }
    .footer-container-2 > div > a {
        font-weight: normal;
    }


    .footer-container-3 {
        width: 90%;
        padding: 30px 0;
        margin: 0 auto;
    }
    .f-cont-3-item-2 > address {
        width: 100%;
    }

}






