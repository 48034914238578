$color-lightblue: #26b8eb;
$color-brightred: #ef3e40;

$transition: all 0.3s ease-in-out;
$box-shadow-1: 0 12px 17px 3px rgb(38, 184, 235, 0.85);
$box-shadow-2: rgba(38, 184, 235, 0.45) 0px 25px 20px -20px;
$box-shadow-strongblue: 0 0 8px 0 rgba(38, 184, 235, 0.7);



.wrap-landing {
    width: 100%;
    height: auto;
    margin: -80px auto 0;
}

// hero
.wrap-hero-ld {
    width: 100%;
    height: 98vh;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url("../images/BG 01-01.png");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
}

.hero-ld-content {
    width: 85%;
    max-width: 1800px;
    height: auto;
    margin: -60px auto 0;

    & > .hero-text-1 {
        width: 50%;
        padding: 0 0 15px;
        margin: 0 0 20px;
        color: #fff;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0px;
        border-bottom: 3px solid $color-brightred;
    }

    & > h1 {
        width: 50%;
        color: $color-lightblue;
        font-size: 45px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.01px;
        margin-bottom: 10px;
    }

    & > p {
        width: 50%;
        color: #fff;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0px;
        margin-bottom: 40px;
    }
}

.hero-learnmore-btn > button {
    color: #fff;
    background-color: $color-lightblue;
    padding: 14px 35px;
    border: none;
    border-radius: 15px;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.5);
    outline: 0;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    transition: $transition;

    &:hover {
        background-color: $color-brightred;
    }
}



// land-container
.land-container {
    width: 100%;
    height: auto;
}

.ld-cont-header {
    width: fit-content;
    margin: 0 auto 50px;
    cursor:default; 

    & > h2 {
        color: $color-lightblue;
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.01px;
        margin-bottom: 10px;
    }

    & > .ld-cont-h-linediv {
        width: 80%;
        margin: 0 auto;
        border-bottom: 5px solid $color-brightred;
    }

    & > p {
        color: #000;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0px;
        text-align: center;
    }
}



// vimeo storyline
.land-content-0 {
    width: 100%;
    height: auto;
    background-image: linear-gradient(rgba(38, 184, 235, 0), rgb(38, 184, 235, 0.2), rgba(38, 184, 235, 0), rgba(38, 184, 235, 0));
    padding: 120px 0 0;
    text-align: center;

    & > div {
        width: 100%;
        height: 450px;
        margin: 0 auto;
        // padding: 56.25% 0 0 0;
        position: relative; 
        text-align: center;

        & iframe {
            width: 65%;
            height: 100%;
            filter: drop-shadow( 0px 4px 12px rgb(172, 182, 193));
            -webkit-filter: drop-shadow(0px 4px 12px rgb(172, 182, 193, 1));
        }
    }
}


// core-business
.land-content-1 {
    width: 100%;
    height: auto;
    background-image: linear-gradient(rgba(38, 184, 235, 0), rgb(38, 184, 235, 0.2), rgba(38, 184, 235, 0), rgba(38, 184, 235, 0));
    padding: 90px 0;
}

.ld-cont-1-container-1 {
    width: 75%;
    max-width: 1600px;
    height: auto;
    margin: 0 auto 200px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 60px;
}

.ld-cont-1-item {
    background-color: #fff;
    padding: 40px 30px;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0 12px 17px 3px rgba(230, 230, 230, 0.5);

    & > img {
        width: 80px;
        height: 80px;
        margin-bottom: 30px;
    }

    & > h3 {
        color: $color-lightblue;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0px;
        text-align: center;
    }

    & > p {
        color: #000;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0px;
        text-align: center;
    }
}

.ld-cont-1-item-linediv {
    width: 25%;
    margin: 10px auto 16px;
    border-bottom: 3px solid $color-brightred;
}

.ld-cont-1-container-2 {
    width: 75%;
    max-width: 1600px;
    height: auto;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
}

.ld-cont-1-item-2 {
    padding: 90px 20px 40px;
    background-image: linear-gradient(to bottom, rgba(38, 184, 235, 0.5), rgba(38, 184, 235, 0.1), rgba(38, 184, 235, 0));
    text-align: center;

    & > img {
        width: 70px;
        height: 70px;
        margin-bottom: 30px;
    }

    & > p {
        color: $color-brightred;
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0px;
    }
    
    & > p > span {
        color: #000;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0px;
    }

    &.item-2-left {
        -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 30%, 0% 0);
        clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 30%, 0% 0);    
    }
    &.item-2-right {
        -webkit-clip-path: polygon(0 30%, 0 100%, 100% 100%, 100% 0, 100% 0);
        clip-path: polygon(0 30%, 0 100%, 100% 100%, 100% 0, 100% 0);    
    }
}



// video
.land-content-2 {
    width: 100%;
    height: auto;
    // background-image: linear-gradient(rgba(38, 184, 235, 0), rgb(38, 184, 235, 0.2), rgba(38, 184, 235, 0), rgba(38, 184, 235, 0));
    padding: 90px 0;
}



// About Us
.land-content-3 {
    width: 100%;
    height: auto;
    padding: 90px 0 0;
}

.ld-cont-3-img-mobile {
    display: none;
}

.ld-cont-3-container-1 {
    width: 100%;
    margin-bottom: 100px;
    background-image: linear-gradient(rgba(255, 255, 255, 1), rgb(255, 255, 255, 0.85), rgba(255, 255, 255, 1)), url('../images/BG_31.png');
    background-position:  center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.ld-cont-3-cont-1-item {
    width: 90%;
    display: flex;
    align-items: center;
}

.ld-cont-3-cont-1-item-1 > img {
    height: 400px;
}

.ld-cont-3-cont-1-item-2 {
    padding: 0 60px;

    & > p {
        color: #000;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: 0px;
        margin-bottom: 30px;
    }
}

.ld-cont-3-container-2 {
    width: 100%;
    padding: 100px 0;
    background-image: linear-gradient(rgba(255, 255, 255, 1), rgb(255, 255, 255, 0.85), rgba(255, 255, 255, 1)), url('../images/BG_29.png');
    background-position:  center;
    background-repeat: no-repeat;
    background-size: cover;
}

.ld-cont-3-cont-2-item {
    width: 75%;
    max-width: 1600px;
    height: auto;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 40% 40%;
    justify-content: space-around;
}

.ld-cont-3-cont-2-item-1 {
    background-color: rgb(255, 255, 255, 1);
    padding: 40px 30px;
    text-align: center;
    border-radius: 45px;
    border: solid 3px $color-lightblue;

    & > h3 {
        color: $color-lightblue;
        font-size: 36px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.01px;
    }

    & > .ld-cont-3-cont-2-item-1-linediv {
        width: 20%;
        margin: 10px auto 20px;
        border-bottom: 3px solid $color-brightred;
    }

    & > p {
        color: #000; 
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.55;
        letter-spacing: 0px;
        text-align: center;
    }
}


// why choose us
.land-content-4 {
    width: 100%;
    height: auto;
    padding: 90px 0;
}

.ld-cont-4-container-1 {
    width: 80%;
    max-width: 1800px;
    height: auto;
    margin: 0 auto 30px;
}

.ld-cont-4-container-1 > figure {
    width: 100%;
    margin: -60px auto 0;

    & > img {
        width: 100%;
    }
}

.ld-cont-4-container-2 {
    width: 75%;
    max-width: 1600px;
    height: auto;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
}

.ld-cont-4-cont-2-item-1 {
    text-align: center;

    & > h3 {
        margin: 0 auto;
        color: $color-lightblue;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0px;
        text-align: center;
        text-transform: capitalize;
    }
    & > .ld-cont-4-linediv {
        width: 20%;
        margin: 10px auto ;
        border-bottom: 3px solid $color-brightred;
    }
    & > p {
        width: 80%;
        margin: 0 auto;
        color: #000;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: 0px;
        text-align: center;
    }
}

.ld-cont-4-container-3 {
    width: 75%;
    max-width: 1600px;
    height: auto;
    margin: 90px auto 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
}

.ld-cont-4-cont-3-item-1 {
    width: 100%;
    height: 300px;
    margin: 0 auto;
    position: relative; 
    text-align: center;

    & iframe {
        width: 100%;
        height: 300px;
    }
}

.ld-cont-4-cont-3-item-2 {
    & > figure {
        width: 100%;
        text-align: center;

        & img {
            cursor: pointer;
            width: 90%;
            border-radius: 5px;
            transition: $transition;
            box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
            
        }
    }
}

.salesvid-modal {
    backdrop-filter: blur(5px);
    
    & .modal-content {
        border: none;
        background: none;
    }

    & .modal-dialog {
        width: 80%;
        max-width: 800px;
        height: 700px;
    }

    & .modal-body {
        padding: 0;
        & iframe {
            width: 100%;
            height: 600px;
        }
    }
}



// company photo
.land-content-5 {
    width: 100%;
    height: auto;
    padding: 30px 0 90px;
}

.ld-cont-5-container-1 {
    width: 75%;
    max-width: 1600px;
    height: auto;
    margin: 0 auto;
}

.ld-cont-5-container-1 > figure {
    width: 60%;
    height: auto;
    margin: 0 auto;
    border-radius: 30px;
    // box-shadow: $box-shadow-1;

    & > img {
        width: 100%;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    }
}


// Customer Reviews
.land-content-6 {
    width: 100%;
    height: auto;
    padding: 90px 0;

    & > p {
        margin: -30px auto 50px;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0px;
        text-align: center;
    }
}

.ld-cont-6-container-1 {
    width: 75%;
    max-width: 1600px;
    height: auto;
    margin: 0 auto;

    & .slick-slide {
        padding: 0 10px;
    }
    & .slick-prev, .slick-next {
        width: 32px !important;
        height: 32px !important;
    }
    & .slick-next:before {
        color: $color-brightred;
        font-size: 30px;
    }
    & .slick-prev:before {
        color: $color-brightred;
        font-size: 30px;
    }
}

.ld-cont-6-item-1 {
    background-color: #fff;
    width: 100% !important;
    height: auto;
    margin: 60px 0;
    padding: 20px;
    border-radius: 20px;
    box-shadow: $box-shadow-strongblue;
}

.ld-review-item-header {
    padding: 0 10px;
    display: flex;
    align-items: center;
    // grid-template-columns:  90px 1fr;

    & > figure {
        width: 70px;
        height: 70px;
        margin: 0 20px 0 0;
        & > img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }

    & > div {
        & > .ld-review-name {
            color: $color-lightblue;
            font-size: 14px;
            font-weight: bold;
            margin: 0 0 4px;
        }

        & > .ld-review-location {
            color: #000;
            font-size: 11px;
            font-weight: normal;
            text-transform: uppercase;
        }

        & > .ld-review-rating {
            & > span {
                font-size: 12px;
                margin: 0 4px 0 0;
            }
            & > .checked {
                color: $color-lightblue;
            }
        }

    } 
}

.ld-review-item-linediv {
    width: 100%;
    margin: 10px 0;
    border-bottom: 3px solid $color-brightred;
}

.ld-review-item-desc {

    & > div {
        color: $color-lightblue;
        font-size: 14px;
        font-weight: bold;
        height: 50px;
    }
    & > p {
        color: #000;
        font-size: 14px;
        font-weight: normal;
        height: 170px;
    }
}


// contact us
.land-content-7 {
    width: 100%;
    height: auto;
    padding: 90px 0;
    background-image: linear-gradient(rgba(255, 255, 255, 1), rgb(255, 255, 255, 0.9), rgba(255, 255, 255, 1)), url('../images/BG_30.png');
    background-position:  top center;
    background-repeat: no-repeat;
    background-size: cover;

    & > p {
        margin: -30px auto 50px;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0px;
        text-align: center;
    }
}

.ld-cont-7-container-1 {
    width: 90%;
    height: auto;
    margin-left: auto;
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 60px;
}

.cont-us-form-content {
    width: 100%;

}

.cont-us-form-row-1 {
    width: 100%;

    
    & > div {
        margin-bottom: 30px;
        & > input {
            color: #999;
            background-color: rgba(242, 242, 247, 0.75);
            width: 80%;
            padding: 10px 20px;
            font-size: 14px;
            font-weight: normal;
            border: 2px solid $color-lightblue;
            border-radius: 10px;
            outline: none;

            &:focus {
                color: #000;
                border: 2px solid $color-brightred;
            }
        }
    }
}

.cont-us-form-row-2 {
    width: 100%;
    margin-bottom: 30px;
    & > textarea {
        color: #999;
        background-color: rgba(242, 242, 247, 0.75);
        width: 80%;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: normal;
        border: 2px solid $color-lightblue;
        border-radius: 10px;
        outline: none;

        &:focus {
            color: #000;
            border: 2px solid $color-brightred;
        }
    }
}

.cont-us-form-row-3 {
    & > button {
        color: #fff;
        background-color: $color-lightblue;
        padding: 12px 40px;
        font-size: 14px;
        font-weight: bold;
        border: none;
        border-radius: 10px;
        outline: 0;
        text-transform: uppercase;
        transition: $transition;

        &:hover {
            background-color: $color-brightred;
        }
    }
}

.ld-cont-7-item-2 {
    
    & img {
        width: 100%;
    }
}



// range of cars
.land-content-8 {
    width: 100%;
    height: auto;
    padding: 90px 0;
}

.ld-cont-8-container {
    width: 75%;
    max-width: 1600px;
    height: auto;
    margin: 0 auto;
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr;
    gap: 60px;
}

.ld-cont-8-item {
    border-radius: 20px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > h3 {
        color: #fff;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-transform: uppercase;
        cursor:default;
    }

    &.hatchback {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgb(0, 0, 0, 0.35), rgba(0, 0, 0, 0.85)), url('../images/BG_22.png');
        background-position:  center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &.suv {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgb(0, 0, 0, 0.35), rgba(0, 0, 0, 0.85)), url('../images/BG_23.png');
        background-position:  center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &.mpv {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgb(0, 0, 0, 0.35), rgba(0, 0, 0, 0.85)), url('../images/BG_24.png');
        background-position:  center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &.sedan {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgb(0, 0, 0, 0.35), rgba(0, 0, 0, 0.85)), url('../images/BG_25.png');
        background-position:  center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &.lorry {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgb(0, 0, 0, 0.35), rgba(0, 0, 0, 0.85)), url('../images/BG_26.png');
        background-position:  center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &.others {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgb(0, 0, 0, 0.35), rgba(0, 0, 0, 0.85)), url('../images/BG_27.png');
        background-position:  center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}


// emailjs alert modal
.modal-backdrop.show {
    opacity: 0.7;
}
.emailJS-modal {

    & .modal-content {
        border-radius: 10px;
        border: none;
    }

    & .modal-header {
        border: none;
        padding: 1rem 1rem 0;
    }

    & .modal-body {
        padding:  0 1rem 1rem;
        text-align: center;

        & img {
            width: 80px;
            height: 80px;
            margin: 0 auto 20px;
        }
        & > h3 {
            color: rgb(45, 45, 45);
            font-size: 24px;
            margin-bottom: 8px;
        }
        & > p {
            color: rgb(88, 88, 88);
            font-size: 16px;
        }
    }

    & .modal-footer {
        border: none;
    }
}



@media screen and (min-width: 1901px) {


    .hero-ld-content > .hero-text-1 {
        width: 45%;
    }
    .hero-ld-content > h1 {
        width: 40%;
        color: $color-lightblue;
        font-size: 45px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.01px;
    }

    .land-content-0 {
        width: 100%;
        height: auto;
        background-image: linear-gradient(rgba(38, 184, 235, 0), rgb(38, 184, 235, 0.2), rgba(38, 184, 235, 0), rgba(38, 184, 235, 0));
        padding: 120px 0 0;
        text-align: center;

        & > div {
            width: 80%;
            height: 500px;
            margin: 0 auto;
            // padding: 56.25% 0 0 0;
            position: relative; 
            text-align: center;

            & iframe {
                width: 55%;
                height: 100%;
                filter: drop-shadow( 0px 4px 12px rgb(172, 182, 193));
                -webkit-filter: drop-shadow(0px 4px 12px rgb(172, 182, 193, 1));
            }
        }
    }

    // aboutus
    .ld-cont-3-cont-1-item-1 > img {
        height: 500px;
    }

    

    .ld-review-item-desc {
        & > p {
            color: #000;
            font-size: 14px;
            font-weight: normal;
            height: 100px;
        }
    }
    
    .ld-cont-4-cont-3-item-2 {
        & > figure {
            width: 100%;
            text-align: center;
    
            & img {
                cursor: pointer;
                width: 80%;
                border-radius: 5px;
                transition: $transition;
                box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
                
            }
        }
    }

    .ld-cont-8-container {
        width: 70%;
        max-width: 1600px;
        height: auto;
        margin: 0 auto;
        display: grid; 
        grid-template-columns: 1fr 1fr 1fr;
        gap: 60px;
    }

    .ld-cont-8-item {
        border-radius: 20px;
        height: 240px;
    }
}



@media screen and (max-width: 1200px) {

    .ld-cont-header > .ld-cont-h-linediv {
        border-bottom: 3px solid $color-brightred;
    }

    .ld-cont-header > h2 {
        font-size: 30px;
        text-transform: capitalize;
    }

    .land-content-0 > div {
        width: 100%;
        height: 400px;
        margin: 0 auto;
        // padding: 56.25% 0 0 0;
        position: relative; 
        text-align: center;

        & iframe {
            width: 80%;
            height: 100%;
        }
    }

    .ld-cont-1-container-1 {
        width: 80%;
        gap: 40px;
        margin-bottom: 100px;
    }

    .ld-cont-3-cont-1-item-2 {
        padding: 0 0 0 60px;
    }

    .ld-cont-4-container-1 {
        width: 90%;
    }
    .ld-cont-4-container-2 {
        width: 90%;
    }
    .ld-cont-4-cont-2-item-1 > p {
        font-size: 14px;
    }
    
    .ld-review-item-header {
        height: 90px;
    }
    .ld-review-item-desc > p {
        height: auto;
        font-size: 12px;
    }
    .ld-cont-6-item-1 {
        height: 340px;
    }

    .ld-cont-7-container-1 {
        grid-template-columns: 1fr 1fr;
    }
    .cont-us-form-row-1 > div > input,
    .cont-us-form-row-2 > textarea {
        width: 100%;
    }


    .ld-cont-8-container {
        width: 80%;
        gap: 30px;
    }
}


@media screen and (max-width: 1024px) {
    .wrap-hero-ld {
        height: 800px;
    }
    .hero-ld-content {
        width: 90%;
    }
    .hero-ld-content > .hero-text-1 {
        width: 70%;
    }
    .hero-ld-content > h1 {
        width: 80%;
        margin-bottom: 20px;
    }
    .hero-ld-content > p {
        font-size: 20px;
    }
    .ld-cont-header > h2 {
        font-size: 30px;
        text-transform: capitalize;
    }
    .ld-cont-header > .ld-cont-h-linediv {
        border-bottom: 3px solid $color-brightred;
    }

    .land-content-0 {
        padding: 60px 0 0;
    }

    .land-content-1 {
        padding: 60px 0 0;
    }
    .ld-cont-1-container {
        width: 90%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
    }

    .ld-cont-1-container-1 {
        width: 90%;
        gap: 20px;
        margin: 0 auto 100px;
    }
    .ld-cont-1-item {
        padding: 30px 20px;
    }
    .ld-cont-1-item > img,
    .ld-cont-1-item-2 > img {
        width: 60px;
        height: 60px;
    }
    .ld-cont-1-item > h3 {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        height: 45px;
    }
    .ld-cont-1-item > p {
        font-size: 14px;
    }
    .ld-cont-1-container-2 {
        width: 90%;
    }
    .ld-cont-1-item-2 {
        
        &.item-2-left {
            -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 0% 0);
            clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 0% 0);    
        }
        &.item-2-right {
            -webkit-clip-path: polygon(0 25%, 0 100%, 100% 100%, 100% 0, 100% 0);
            clip-path: polygon(0 25%, 0 100%, 100% 100%, 100% 0, 100% 0);    
        }
    }
    .ld-cont-1-item-2 > p {
        font-size: 18px;
        line-height: 1.5;
        & > span {
            font-size: 14px;
        }
    }

    .land-content-3 {
        padding: 90px 0 0;
    }
    .ld-cont-3-container-1 {
        margin-bottom: 60px;
    }
    .ld-cont-3-cont-1-item {
        width: 100%;
        align-items: normal;
        flex-direction: column;
    }
    .ld-cont-3-cont-1-item-1 {
        margin-bottom: 30px;
    }
    .ld-cont-3-cont-1-item-1 > img {
        height: 350px;
        float: left;
    }
    .ld-cont-3-cont-1-item-2 {
        margin: 0 auto;
    }
    .ld-cont-3-container-2 {
        padding: 0;
    }
    .ld-cont-3-cont-2-item {
        width: 90%;
        grid-template-columns: 45% 45%;
    }
    .ld-cont-3-cont-2-item-1 > h3 {
        font-size: 28px;
    }


    .land-content-4 {
        padding: 90px 0 0;
    }
    .ld-cont-4-container-1 {
        width: 95%;
    }
    .ld-cont-4-container-2 {
        width: 90%;
    }
    .ld-cont-4-cont-2-item-1 > p {
        font-size: 14px;
        width: 100%;
    }
    .ld-cont-4-cont-2-item-1 > h3 {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .land-content-5 {
        padding: 90px 0 0;
    }
    .ld-cont-5-container-1 > figure {
        width: 80%;
    }


    .land-content-6 {
        padding: 90px 0 0;
    }
    .land-content-6 > p {
        font-size: 14px;
    }
    .ld-cont-6-container-1 {
        width: 90%;
        padding: 0 40px;
    }
    .ld-cont-6-item-1 {
        margin: 30px 0;
    }


    .land-content-7 {
        padding: 90px 0 0;
    }


    .ld-cont-8-container {
        width: 90%;
        gap: 40px 20px;
    }
    .ld-cont-8-item {
        height: 160px;
    }


    // email alert modal
    .emailJS-modal .modal-body img {
        width: 70px;
        height: 70px;
    }
    .emailJS-modal .modal-body > h3 {
        font-size: 18px;
    }
    .emailJS-modal .modal-body > p {
        font-size: 14px;
    }

}


@media screen and (max-width: 576px) {
    
    .wrap-landing {
        width: 100%;
        height: auto;
        margin: 0 auto;
    }
    .wrap-hero-ld {
        width: 100%;
        height: 450px;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url("../images/BG 01.png");
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .hero-ld-content > .hero-text-1 {
        width: 70%;
        font-size: 14px;
    }
    .hero-ld-content > h1 {
        width: 90%;
        font-size: 22px;
        font-weight: bold;
    }
    .hero-ld-content > p {
        width: 100%;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .hero-learnmore-btn > button {
        font-size: 12px;
        padding: 10px 16px;
        border-radius: 10px;
    }

    .ld-cont-header {
        margin-bottom: 20px;
    }
    .ld-cont-header > h2 {
        font-size: 20px;
    }
    .ld-cont-header > .ld-cont-h-linediv {
        border-bottom: 3px solid $color-brightred;
    }


    .land-content-0 {
        padding: 0;
    }
    .land-content-0 > div {
        width: 100%;
        height: 330px;

        & iframe {
            width: 99%;
            height: 100%;
        }
    }
    

    .land-content-1 {
        padding: 0;
    }
    .ld-cont-1-container {
        width: 90%;
        display: grid;
        grid-template-columns: 1fr;
        gap: 30px;
    }
    .ld-cont-1-container-1 {
        grid-template-columns: 1fr;
    }
    .ld-cont-1-item > h3 {
        height: auto;
        font-size: 16px;
    }
    .ld-cont-1-item > p {
        font-size: 12px;
    }
    .ld-cont-1-container-2 {
        grid-template-columns: 1fr 1fr;
    }
    .ld-cont-1-item-2 {
        padding: 80px 20px 0;
        & > p {
            font-size: 14px;
            font-weight: bold;
            line-height: 1.2;
        }
        & > p > span {
            font-size: 12px;
            font-weight: normal;
        }
        &.item-2-mob-left {
            -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 20%, 0% 0);
            clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 20%, 0% 0);    
        }
        &.item-2-mob-right {
            -webkit-clip-path: polygon(0 20%, 0 100%, 100% 100%, 100% 0, 100% 0);
            clip-path: polygon(0 20%, 0 100%, 100% 100%, 100% 0, 100% 0);    
        }
    }


    .land-content-3 {
        width: 100%;
    }
    .ld-cont-3-img-mobile {
        display: initial;
        width: 100%;
        height: auto;
        padding-right: 90px;
        margin-bottom: 20px;
        background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 1)), url('../images/BG_31.png');
        background-position: center center;        
        background-repeat: no-repeat;
        background-size: 200%;
    }
    .ld-cont-3-container-1 {    
        background-image: none;
        margin-bottom: 20px;
    }
    .ld-cont-3-cont-1-item-1,
    .ld-cont-3-cont-1-item-1 > img {
        display: none;
    }
    .ld-cont-3-cont-1-item-2 {
        width: 90%;
        margin: 0 auto;
        padding: 0;
    }
    .ld-cont-3-cont-1-item-2 > p {
        font-size: 12px;
    }
    .ld-cont-3-cont-2-item {
        grid-template-columns: 1fr;
    }
    .ld-cont-3-cont-2-item-1 {
        margin-bottom: 30px;
        padding: 30px ;
        border-radius: 20px;
    }
    .ld-cont-3-cont-2-item-1 > h3 {
        font-size: 18px;
    }
    .ld-cont-3-cont-2-item-1 > p {
        font-size: 14px;
    }


    .ld-cont-4-container-1 > figure {
        margin: 0 auto;
    }
    .ld-cont-4-container-2 {
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .ld-cont-4-cont-2-item-1 > h3 {
        height: auto;
        font-size: 16px;
    }
    .ld-cont-4-cont-2-item-1 > .ld-cont-4-linediv {
        border-bottom: 2px solid $color-brightred;
    }
    .ld-cont-4-cont-2-item-1 > p {
        font-size: 12px;
    }
    .ld-cont-4-container-3 {
        width: 99%;
        height: auto;
        margin: 30px auto 0;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
    
    .ld-cont-4-cont-3-item-1 {
        width: 100%;
        height: 200px;

        & iframe {
            width: 100%;
            height: 200px;
        }
    }
    .salesvid-modal .modal-dialog {
        width: 100%;
        max-width: 100%;
        height: 200px;
        margin: 0;
    }
    .salesvid-modal .modal-body iframe {
        height: 25vh;
    }

    .ld-cont-5-container-1{
        width: 90%;
    }
    .ld-cont-5-container-1 > figure {
        width: 100%;
    }
    .ld-cont-5-container-1 > figure > img {
        border-radius: 14px;
    }

    .land-content-6 > p {
        width: 90%;
        font-size: 14px;
        margin: -5px auto 0;
    }
    
    .ld-review-item-desc {
        & > p {
            color: #000;
            font-size: 12px;
            font-weight: normal;
            height: 120px;
        }
    }



    .land-content-7 > p {
        width: 90%;
        font-size: 14px;
        margin: -5px auto 30px;
    }
    .ld-cont-7-container-1 {
        width: 100%;
        grid-template-columns: 1fr;
        margin: 0 auto;
    }
    .ld-cont-7-item-1 {
        width: 90%;
        margin: 0 auto;
    }
    .cont-us-form-row-1 > div {
        margin-bottom: 20px;
    }
    .cont-us-form-row-1 > div > input {
        font-size: 12px;
    }
    .cont-us-form-row-2  {
        margin-bottom: 20px;
    }
    .cont-us-form-row-3 {
        text-align: right;
    }


    .ld-cont-8-container {
        width: 90%;
        grid-template-columns: 1fr 1fr;
        gap: 14px ;
    }
    .ld-cont-8-item {
        height: 110px;
        border-radius: 10px;
    }
    .ld-cont-8-item > h3 {
        font-size: 14px;
    }


    // email alert modal
    .emailJS-modal .modal-body img {
        width: 60px;
        height: 60px;
    }
    .emailJS-modal .modal-body > h3 {
        font-size: 16px;
    }
    .emailJS-modal .modal-body > p {
        font-size: 13px;
    }
}

@media screen and (max-width: 400px) {

    
    .ld-review-item-header {
        padding: 0;
    
        & > figure {
            width: 70px;
            height: 70px;
            margin: 0 10px 0 0;
            & > img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
    
        & > div {
            & > .ld-review-name {
                color: $color-lightblue;
                font-size: 14px;
                font-weight: bold;
                margin: 0 0 4px;
            }
    
            & > .ld-review-location {
                font-size: 10px;
            }
    
            & > .ld-review-rating {
                & > span {
                    font-size: 10px;
                }
            }
    
        } 
    }
    .ld-review-item-desc {
        & > p {
            color: #000;
            font-size: 12px;
            font-weight: normal;
            height: 140px;
        }
    }
}