$background: #fff;

$fontcolor_white: #fff;
$fontcolor_grey: #7a7a7a;
$fontcolor_dark: #272727;
$color-red: #f91e39;
$color-lightred: #ff3950;
$discount-green: #00920f;
$shopee-orange: #ee4d2d;

$border-radius-5: 5px;
$border-radius-10: 10px;


.PageError {
    width: auto;
    height: 94vh;
    background: $background;
    margin: -60px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.PageError > h1 {
    color: #000;
    font-size: 60px;
    margin: 0 auto 40px;
}

.PageError > div > p {
    color: #000;
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 40px;
}

.b-to-homepage-btn {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background-color: #000;
    text-decoration: none;
    padding: 14px 40px;
    border: 2px solid #000;
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
}

.b-to-homepage-btn:hover {
    color: #fff;
    background-color: $fontcolor_dark;
    border: 2px solid #000;
   
}