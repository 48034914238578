$color-lightblue: #26b8eb;
$color-brightred: #ef3e40;

$transition: all 0.3s ease-in-out;

.mobile-nav {
    display: none !important;
}

.navbar {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1));
    width: 100%;
    height: 80px;
    margin: 0 auto;
    padding: 0 !important;
    display: flex;
    font-size: 20px;
    // position: sticky !important;
    top: 0;
    z-index: 20;
    /* backdrop-filter: blur(5px); */
}

.navbar-container {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 60px;
}
  
.navbar-logo { 
    display: flex;
    width: 180px;
    color: #fff;
    margin: 0 ;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}
.navbar-logo > img {
    width: 170px;
}
  
.fa-firstdraft {
    margin-left: 0.5rem;
    font-size: 16px;
}
  
.nav-menu {
    display: flex;
    height: 80px;
    margin: 0 auto;
    padding: 0;
}

.nav-item {
    /* width: 100%; */
    margin: 0 30px;
    color: $color-lightblue;
    display: flex;
    align-items: center;
}

.nav-item > a:hover {
    color: $color-lightblue;
}

.nav-links {
    width: 100%;
    height: 80px;
    color: $color-lightblue;
    padding: 30px 0;
    font-size: 14px;
    font-weight: bold;
    text-transform: initial;
    text-decoration: none;
    // letter-spacing: 0.3px;
    cursor: pointer;

    &:hover {
        color: #ef3e40;
    }
}
.nav-item > p {
    margin-bottom: 0;
}

.fa-bars {
    color: $color-lightblue;
    font-size: 20px;
}

.nav-links-mobile {
    display: none;
}
  
.menu-icon {
    display: none;
}

.nav-languages {
    display: flex;
    flex-direction: row;
}

.nav-logo-mb {
    display: none;
}

.navmenu-icon-1 {
    color: $color-lightblue;
    margin-right: 20px;
}


.nav-container-2 {
    display: flex; 
}

.nav-content-fb, .nav-content-priv {
    display: flex;
    height: 36px;
    margin-left: 20px;
    padding: 0 20px ;
    border: 1px solid $color-lightblue;
    border-radius: 4px;
    text-decoration: none;
    color: $color-lightblue;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.nav-content-fb:hover, .nav-content-priv:hover {
    color: $color-lightblue;
    border: 1px solid $color-lightblue;
}

.nav-content-links {
    font-size: 12px;  
    font-weight: normal;
    display: flex;
    align-items: center;
}



// nav-dropdown 
.nav-dropdown {

    & button {
        color: $color-brightred;
        background-color: transparent !important;
        font-size: 14px;
        font-weight: bold;
        border: none;
        transition: $transition;
        &:hover {
            color: $color-brightred !important;
        }
        &:focus {
            color: $color-brightred !important;
            box-shadow: none;
            border: none;
        }
    }
}

.btn-check:active+.btn-primary, 
.btn-check:checked+.btn-primary, 
.btn-primary.active, .btn-primary:active, 
.show>.btn-primary.dropdown-toggle {
    color: $color-brightred;
    box-shadow: none !important;
    border-color: transparent;
}

.nav-dropdown .dropdown-menu {
    background-color: transparent;
    width: 120px;
    border: none;
    outline: 0;
}

.nav-dropdown .dropdown-item {
    color: $color-lightblue;
    background-color: #fff;
    margin: 10px 0;
    padding: 10px ;
    font-size: 12px;
    text-align: center;
    border: solid 1px $color-lightblue;
    border-radius: 8px;
    transition: $transition;
    &:hover {
        color: $color-brightred;
        border: solid 1px $color-brightred;
    }
}

  
/* Responsive */
@media screen and (max-width: 1200px) {
    .navbar {
        position: sticky !important;
    }

    .landing-nav {
        display: none !important;
    }

    .mobile-nav {
        display: flex !important;
    }

    .NavbarItems {
      position: relative;
    }

    .navbar {
        height: 70px;
        z-index: 12;
        background-color: #000; 
    }

    .navbar-container {
        width: 90%;
        padding: 0;
        margin: 0 auto;
        display: flex;
        align-items: center;
        // justify-content: space-between;
    }

    .navbar-container.mobile-nav {
        height: 70px;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 90%;
        height: auto;
        position: absolute;
        top: 70px;
        padding: 0;

        left: -150%;
        opacity: 1;
        /* transition: all 0.1s ease; */
    }

    .nav-menu.active {
        background: #fff; 
        left: 0;
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
        opacity: 1;
        z-index: 2;
        background-color: #000; 
        box-shadow: -2px 400px 0px 200px rgba(0,0,0,0.8);
        -webkit-box-shadow: -2px 600px 0px 600px rgba(0,0,0,0.8);
        -moz-box-shadow: -2px 600px 0px 600px rgba(0,0,0,0.8);

    }

    .nav-item {   
        margin: 0;
        width: 90%;
        height: 60px;
        background-color: #000; 
    }

    .nav-links {
        text-align: left;
        width: 90%;
        display: table;
        margin: 0 auto;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff !important;
    }

    .navbar-logo {
        margin: 0;
    }

    .nav-media {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
        color: white;
    }
    
    .nav-social {
        padding-right: 0;
    }

    .social-icon-link {
        margin: 0 12px;
        color: #fff;
        font-size: 24px;
    }

    .menu-icon {
        display: flex;
        justify-content: flex-end;
        width: 40px;
        /* position: absolute; */
        margin: 0 0 0;
        font-size: 1.5rem;
        cursor: pointer;
    }

    .fa-times {
        color: $color-brightred;
        font-size: 22px;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
    }

    .nav-links-mobile:hover {
        background: #fff;
        color: $color-lightblue;
        transition: 250ms;
    }

    .nav-logo-mb {
        width: 100px;
        display: flex;
        margin: -5px 0 0 -130px;
    }


    .nav-dropdown {
        // margin-right: -300px;
        margin-left: auto;
        & button {
            color: $color-lightblue;
            background-color: transparent !important;
            font-size: 14px;
            font-weight: bold;
            border: none;
            transition: $transition;
            &:hover {
                color: $color-brightred !important;
            }
            &:focus {
                color: $color-brightred !important;
                box-shadow: none;
                border: none;
            }
        }
    }
}


@media screen and (max-width: 576px) {
    .navbar {
        position: sticky !important;
    }

    .navbar-logo > img {
        width: 120px;
    }

    .nav-menu.active {
        background: rgba(0, 0, 0, 0.8);
    }

    .nav-menu.active .nav-item {
        height: 50px;
        margin: 10px auto;
        background-color: #fff;
        border-radius: 10px;
        text-align: center;
        display: flex;
        align-items: center;
        border: solid 1px $color-lightblue;
    }

    .nav-links {
        height: 50px;
        color: $color-lightblue !important;
        text-align: center;
    }

    .menu-icon {
        width: 30px;
    }

    // .nav-dropdown {
    //     margin-right: -60px;
    // }
    .nav-dropdown .dropdown-menu {
        background-color: rgb(0, 0, 0, 0.8);
        width: 100vw;
        height: 100vh;
    }
    .nav-dropdown .dropdown-item {
        width: 90%;
        margin: 20px auto;
        padding: 14px 0;
        font-size: 14px;
        font-weight: bold;
    }
}